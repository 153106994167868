import React from "react";
import { Grid, Form, Container, Segment, Input } from "semantic-ui-react";

const initialItems = [
  { job: "Surgery Ward Driver", occupation: "Medical Driver", industry: "Medical Industry" }
];

const getUpdateItems = (items, index, field, value) => {
  const newItems = [...items];
  newItems[index] = { ...newItems[index], [field]: value };
  if (
    newItems[index].job === "" &&
    newItems[index].occupation === "" &&
    newItems[index].industry === ""
  ) {
    newItems.splice(index, 1);
  }
  return newItems;
};

class App extends React.Component {
  state = { items: initialItems };

  render() {
    const { items } = this.state;
    console.log(items);
    return (
      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={5} />
            <Grid.Column width={6}>
              <Segment.Group>
                {items.map(({ job, occupation, industry }, i) => (
                  <Segment color="blue" key={"job-" + i}>
                    <Form>
                      <Input
                        style={{ marginBottom: "1em" }}
                        fluid
                        ref={ref => (this.jobRef = ref)}
                        value={job}
                        placeholder="Desired Job"
                        onChange={({ target: { value } }) =>
                          this.setState({
                            items: getUpdateItems(items, i, "job", value)
                          })
                        }
                      />
                      <Input
                        style={{ marginBottom: "1em" }}
                        fluid
                        ref={ref => (this.occupationRef = ref)}
                        value={occupation}
                        placeholder="Occupation"
                        onChange={({ target: { value } }) =>
                          this.setState({
                            items: getUpdateItems(items, i, "occupation", value)
                          })
                        }
                      />
                      <Input
                        fluid
                        ref={ref => (this.industryRef = ref)}
                        value={industry}
                        placeholder="Industry"
                        onChange={({ target: { value } }) =>
                          this.setState({
                            items: getUpdateItems(items, i, "industry", value)
                          })
                        }
                      />
                    </Form>
                  </Segment>
                ))}
                <Segment color="green">
                  <Form>
                    <Form.Input
                      value=""
                      placeholder="Desired Job"
                      onChange={({ target: { value } }) => {
                        this.setState({
                          items: [
                            ...items,
                            { job: value, occupation: "", industry: "" }
                          ]
                        });
                        setTimeout(() => this.jobRef.focus());
                      }}
                    />
                    <Form.Input
                      value=""
                      placeholder="Occupation"
                      onChange={({ target: { value } }) => {
                        this.setState({
                          items: [
                            ...items,
                            { job: "", occupation: value, industry: "" }
                          ]
                        });
                        setTimeout(() => this.occupationRef.focus());
                      }}
                    />
                    <Form.Input
                      value=""
                      placeholder="Industry"
                      onChange={({ target: { value } }) => {
                        this.setState({
                          items: [
                            ...items,
                            { job: "", occupation: "", industry: value }
                          ]
                        });
                        setTimeout(() => this.industryRef.focus());
                      }}
                    />
                  </Form>
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default App;
